@import './variables';
@import "./fonts";

*,
*::before,
*::after,
html,
body,
input,
button,
textarea,
label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  width: 100%;
  line-height: 1.6;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
input,
textarea,
button,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: $font-size-extra-large;
  font-family: "Kanit Bold";

  @media screen and (max-width: $tablet-land) {
    font-size: $font-size-48;
  }

  @media screen and (max-width: $tablet-port) {
    font-size: $font-size-48;
  }

  @media screen and (max-width: $mobile) {
    font-size: $font-size-medium;
  }
}

h2 {
  font-size:  $font-size-medium;
  font-family: "Kanit ExtraLight";
  font-weight: unset;
  @media screen and (max-width: $tablet-land), (max-width: $tablet-port) {
    font-size: $font-size-large-mobile;
  }
  @media screen and (max-width: $mobile) {
    font-size: $font-size-small;
  }
}


body,
input,
textarea,
button,
p,
*,
*:hover,
*:focus,
a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none !important;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: #ffffff;
}


button,
input , textarea {
  -webkit-appearance: none;
  color: $content-text-color;
  font-family: "Kanit Light";
  font-size: $font-size-regular;
  padding-left: 24px;

  @media screen and (max-width: $mobile ) {
    font-size: $font-size-regular-mobile;
  }

  &::placeholder {
    font-size: $font-size-regular;
    font-family: "Kanit ExtraLight";
    color: #999999;
    @media screen and (max-width: $tablet-port ) {
      font-size: $font-size-extra-small;
    }
    @media screen and (max-width: $mobile ) {
      font-size: $font-size-regular-mobile;
    }
  }
}

textarea {
  padding-top: 17px;
}

body.preload *{
  @media screen and (max-width: $mobile ) {
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
  }
}

#root {
  width: 100%;
  height: 100%;
  .main {
    overflow-x: hidden;
  }
}

.link-reset {
  &:link, &:hover {
    text-decoration: underline !important;
  }
}


/* Text Content Layout*/

.text-content-layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1047px;
  @media screen and (max-width: $tablet-land ) {
    width: 820px
  }
  @media screen and (max-width: $tablet-port ) {
    width: 643px
  }
  @media screen and (max-width: $mobile ) {
    width: 85%;
  }
}

.inc-h1 {
  font-family: "Kanit Bold";
  font-size: $font-size-large;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media screen and (max-width: $tablet-land) {
    font-size: $font-size-large-tablet;
  }
  @media screen and (max-width: $mobile) {
    font-size: $font-size-large-mobile;
  }
}

.cont-regular {
  font-family: "Kanit ExtraLight";
  font-size: $font-size-regular;

  @media screen and (max-width: $tablet-land) {
    font-size: $font-size-extra-small;
  }

  @media screen and (max-width: $mobile) {
    font-size: $font-size-regular-mobile;
  }
}

.mt-50 {
  margin-top: 50px;
}

.mt-26 {
  margin-top: 26px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-26 {
  padding-right: 26px;
}

.spacer-100 {
  width: 2%;
}

.deco-line-1 {
  background-image: url("../assets/deco/curve-line-up.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 66px;
  margin-top: -33px;
  @media screen and (max-width: $tablet-land) {
    background-image: url("../assets/deco/tablet/curve-line-up-land.svg");
    margin-top: -17px;
  }
  @media screen and (max-width: $tablet-port) {
    background-image: url("../assets/deco/tablet/curve-line.svg");
    margin-top: -17px;
  }
  @media screen and (max-width: $mobile) {
    background-image: url("../assets/deco/mobile/curve-line-up.svg");
    margin-top: -12px;
  }
}

.deco-line-double {
  background-image: url("../assets/deco/curve-line-double.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 66px;
  margin-top: 60px;

  @media screen and (max-width: $tablet-land) {
    background-image: url("../assets/deco/tablet/curve-line-double-land.svg");
    background-size: cover;
  }
  @media screen and (max-width: $tablet-port) {
    background-image: url("../assets/deco/tablet/curve-line-double-port.svg");
    background-size: unset;
  }
  @media screen and (max-width: $mobile) {
    background-image: url("../assets/deco/mobile/curve-line-double-mobile.svg");
    background-size: unset;
    margin-top: 10px;
  }
}

.deco-line-down {
  background-image: url("../assets/deco/curve-line-down.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 66px;
  margin-top: 25px;
  position: relative;
  @media screen and (max-width: $mobile) {
    background-image: url("../assets/deco/mobile/curve-line-down.svg");
  }

  &.map-deco {
    margin-top: -33px;
    @media screen and (max-width: $tablet-land ) {
      margin-top: -11px;
    }
    @media screen and (max-width: $mobile) {
      margin-top: -5px;
    }
  }
}

.mask {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #1F1714;
  opacity: 0.6;
  top: 0px;
  left: 0px;
  z-index: 2001;
  display: none;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-reset {
  margin-bottom: 0px !important;
}

ul.no-bullets {
  list-style-type: none;
}

.inc-h2 {
  font-family: "Kanit Medium";
  font-size: 25px;
  padding-bottom: 15px;
}

.inc-h3 {
  font-family: "Kanit Medium";
  font-size: $font-size-regular;
}

.inc-h4 {
  font-family: "Kanit Regular";
  font-size: $font-size-regular;
}


.font-regular {
  font-size: $font-size-regular;
  @media screen and (max-width: $tablet-land ) {
    font-size: $font-size-extra-small;
  }
  @media screen and (max-width: $mobile ) {
    font-size: $font-size-regular-mobile;
  }
}


.main-page-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $content-text-color;

  .block-regular {
    font-family: "Kanit Regular";
  }
  .block-extra-light {
    font-family: "Kanit ExtraLight";
  }

  .to-uppercase {
    text-transform: uppercase;
  }

  .text-align-center {
    text-align: center;
  }

  .m-t-100 {
    margin-top: 100px;
    @media screen and (max-width: $mobile ) {
      margin-top: 50px;
    }
  }
  .m-b-100 {
    margin-bottom: 100px;
    @media screen and (max-width: $mobile ) {
      margin-bottom: 50px;
    }
  }

  .m-b-40 {
    margin-bottom: 40px;
    @media screen and (max-width: $mobile ) {
     margin-bottom: 20px;
    }
  }

  .m-b-50 {
    margin-bottom: 50px;
    @media screen and (max-width: $mobile ) {
      margin-bottom: 25px;
    }
  }

  .m-b-100 {
    margin-bottom: 100px;
    @media screen and (max-width: $mobile ) {
      margin-bottom: 50px;
    }
  }

  .m-t-55 {
    margin-top: 55px;
    @media screen and (max-width: $mobile ) {
      margin-top: 27px;
    }
  }

  .m-t-75 {
    margin-top: 75px;
    @media screen and (max-width: $mobile ) {
      margin-top: 33px;
    }
  }

  .m-t-b-90 {
    margin-top: 90px;
    margin-bottom: 90px;
    @media screen and (max-width: $mobile ) {
      margin-top: 43px;
      margin-bottom: 43px;
    }
  }


  .sub-header {
    font-family: "Kanit Bold";
    font-size: 28px;
    text-align: center;
    @media screen and (max-width: $mobile ) {
      font-size: 20px;
    }
  }

  .text-content-block {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: $content-desktop-width;
    font-size: $font-size-regular;
    @media screen and (max-width: $tablet-land ) {
      width: 820px;
      font-size: $font-size-extra-small;
    }
    @media screen and (max-width: $tablet-port ) {
      width: 643px;
    }
    @media screen and (max-width: $mobile ) {
      font-size: $font-size-regular-mobile;
      width: 85%;
    }

    ul > li {
      padding-bottom: 25px;
    }

  }


  .info-cards-block {
    width: 960px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $tablet-land) {
      width: 900px;
    }
    @media (max-width: $tablet-port) {
      width: 450px;
    }
    @media (max-width: $mobile) {
      width: 350px;
    }

    .cards-holder {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }

  .service-cards-block {
    width: 1180px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $tablet-land) {
      width: 900px;
    }
    @media (max-width: $tablet-port) {
      width: 650px;
    }
    @media (max-width: $mobile) {
      width: 85%;
    }

    .cards-holder {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      @media (max-width: $tablet-port) {
        justify-content: center;
      }
      .card-content {
        width: 533px;
        @media (max-width: $tablet-land) {
          width: 400px;
        }
        @media (max-width: $tablet-port) {
          width: 530px;
        }
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }

  .service-cards-block-single, .brand-reference-card-block {
    width: 100%;
    height: 100%;
    display: flex;
    color: $content-text-color;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: $mobile) {
      margin-top: 35px;
    }

    .block-header {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 75px;
      @media (max-width: $mobile) {
        font-size: $font-size-regular-mobile;
        margin-bottom: 55px;
      }
      .sub-header{
        font-family: "Kanit Light";
        font-size: $font-size-regular;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding-top: 18px;
        color:$content-subheader-color;
        @media (max-width: $tablet-land) {
          font-size: $font-size-extra-small;
        }
        @media (max-width: $mobile) {
          font-size: $font-size-regular-mobile;
          padding: 28px 55px 0px 55px;
        }
      }
    }
    .cards-holder {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: $content-desktop-width;
      @media (max-width: $tablet-land) {
        width: 820px;
      }
      @media (max-width: $tablet-port) {
        width: 602px;
      }
      @media (max-width: $mobile) {
        width: 85%;
      }
    }
    .brand-reference-card-holder {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding-left: 123px;
      @media (max-width: $tablet-port) {
        justify-content: center;
      }
      .card-content {
        width: 200px;
        @media (max-width: $tablet-land) {
          width: 150px;
        }
        @media (max-width: $tablet-port) {
          width: 200px;
        }
        @media (max-width: $mobile) {
          width: 125px;
        }
      }
    }
  }
}

.standard-page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .standard-content {
    width: 995px;
    font-family: "Kanit ExtraLight";
    font-size: $font-size-regular;
    color: $content-text-color;
    display: flex;
    flex-direction: column;
    margin-top: 65px;
    margin-bottom: 140px;

    p {
      margin-bottom: 15px;
    }

    @media screen and (max-width: $tablet-land) {
      width: 800px;
    }

    @media screen and (max-width: $tablet-port) {
      width: 600px;
    }

    @media (max-width: $mobile) {
      font-size: $font-size-regular-mobile;
      width: 295px;
      margin-top: 40px;

      p {
        margin-bottom: 20px;
      }
    }
  }
}

.cookie-banner-container {
  font-family: "Kanit Regular";
  padding: 15px;
  align-items: baseline;
  color: $primary-white;
  display: flex;
  flex-wrap: wrap;
  left: 0px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  background: #7E7667;
  z-index: 10000;
  font-size: $font-size-extra-small;
  @media screen and (max-width: $tablet-land) {
    justify-content: flex-end;
  }
  @media screen and (max-width: $mobile ) {
    font-size: $font-size-regular-mobile;
  }
  .cookie-btn {
    border: none;
    padding: 0px 25px;
    margin-left: 50px;
    cursor: pointer;
  }

}