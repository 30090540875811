@import '../../../styles/variables';
.hexagon-icon-holder {
  mask: url("../../../assets/deco/icons/hexagon-icon-holder.svg") no-repeat center;
  -webkit-mask: url("../../../assets/deco/icons/hexagon-icon-holder.svg") no-repeat center;
  width: 48px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;

  &.simple {
    mask: unset;
    -webkit-mask: unset;
    background-image:  url("../../../assets/deco/icons/hexagon-icon-holder-simple.svg");
    background-repeat: no-repeat;
    width: 64px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    @media screen and (max-width: $mobile) {
      width: 50px;
      height: 44px;
      font-size: 18px;
    }
  }

  .uniform_icon {
    background-image: url("../../../assets/deco/icons/uniform_logo.svg");
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    @media screen and (max-width: $mobile) {
      width: 17px;
      height: 17px;
    }
  }

  .optimizely_icon {
    background-image: url("../../../assets/deco/icons/optimizely_logo.svg");
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    @media screen and (max-width: $mobile) {
      width: 17px;
      height: 17px;
    }
  }

  .experience_icon {
    background-image: url("../../../assets/deco/icons/hexagon-sitecore-logo.svg");
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    @media screen and (max-width: $mobile) {
      width: 17px;
      height: 17px;
    }
  }

  .net_icon {
    background-image: url("../../../assets/deco/icons/hexagon-net.svg");
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    @media screen and (max-width: $mobile) {
      width: 17px;
      height: 17px;
    }
  }

  .consultancy_icon {
    background-image: url("../../../assets/deco/icons/hexagon-consultancy.svg");
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    @media screen and (max-width: $mobile) {
      width: 17px;
      height: 17px;
    }
  }

  .netcard_icon {
    background-image: url("../../../assets/deco/icons/net-card-icon.svg");
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    @media screen and (max-width: $mobile) {
      width: 17px;
      height: 17px;
    }
  }

  .comment-smile-icon {
    background-image: url("../../../assets/deco/icons/comment-smile-icon.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 21px;
    @media screen and (max-width: $mobile) {
      width: 17px;
      height: 15px;
    }
  }

  .expanding_icon {
    background-image: url("../../../assets/deco/icons/hexagon-sitecore-expanding.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 29px;
    @media screen and (max-width: $mobile) {
      width: 16px;
      height: 19.1px;
    }
  }

  .expending-red {
    background-image: url("../../../assets/deco/icons/custom-expanding-red.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 29px;
    @media screen and (max-width: $mobile) {
      width: 16px;
      height: 19.1px;
    }
  }

  .content-hub-red {
    background-image: url("../../../assets/deco/icons/content-hub-red.svg");
    background-repeat: no-repeat;
    width: 32px;
    height: 33px;
  }

  @media screen and (max-width: $mobile) {
    width: 32px;
    height: 35.5px;
    font-size: 14.4px;
  }
}

