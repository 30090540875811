@import '../../styles/variables';

.inc-button {

  background-color:transparent;
  border-radius: 50px !important;
  letter-spacing: 2px !important;
  border: 0.9pt solid #D9D9D9 !important;  
  height: 72px !important;

  @media (max-width: $mobile) {
      max-height: 52px;
    }

  .MuiButton-lable {
    padding: 30px 35px 30px 30px !important;
    font-family: "Kanit Medium", san-serif !important;
    font-size: 16px !important;
  }

  &.header-type {
    border: 1px solid #E69B7B !important;
    @media (hover: hover) {
      &:hover {
        background-color: $primary-white !important;
        color: #333333 !important;

        .include_custom_arrow {
          background-color: #333333;
        }
        /* border-color: $primary-white;
         background-color: unset;*/
      }
    }
  }

  &.content-type {
    @media (hover: hover) {
      &:hover {
        background-image: $primary-header-gradient !important;
        color: $primary-white !important;

        /*border-color: #999999;*/

        .include_custom_arrow {
          background-color: $primary-white !important;
        }
      }
    }
  }

  .include_custom_arrow {
    mask: url("../../assets/deco/icons/button-arrow.svg") no-repeat center;
    -webkit-mask: url("../../assets/deco/icons/button-arrow.svg") no-repeat center;
    background-color: $primary-white;

    &.content_icon {
      background-color: $content-arrow-icon-color;
    }
  }

}
