
$primary-header-gradient: linear-gradient(90deg, rgba(237,164,62,1) 0%, rgba(222,69,77,1) 100%);

$content-text-color: #242528;
$content-subheader-color: #737B8C;
$content-arrow-icon-color: #FF9224;
$nested-menu-parent-item-color: #808080;
$nested-menu-link-item-color: #666666;


$font-size-extra-large: 56px;
$font-size-large: 42px;
$font-size-large-tablet: 38px;
$font-size-large-mobile: 26px;
$font-size-medium: 32px;
$font-size-regular: 20px;
$font-size-regular-mobile: 14px;
$font-size-small: 18px;
$font-size-extra-small: 16px;
$font-size-super-extra-small: 12px;
$font-size-48: 48px;

$primary-white: #ffffff;
$primary-black: #000000;

$primary-gray: #7E7667;
$secondary-gray: #979081;

$desktop: 1200px;
$tablet-land: 1024px;
$tablet-port: 812px;
$mobile: 600px;

$content-padding: 0px 145px;

$content-desktop-width: 960px;



@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -o-transition: $property;
  transition:$property;
}

