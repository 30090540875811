@import '../../../styles/variables';
.info-card-simple {
  width: 464px;
  height: 232px;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 55px;

  @media screen and (max-width: $tablet-land) {
    width: 435px;
  }
  @media screen and (max-width: $mobile) {
    width: 350px;
    height: 150px;
  }

  &:nth-child(2n) {
    margin-left: 32px;
    @media screen and (max-width: $tablet-land) {
      margin-left: 15px;
    }
    @media screen and (max-width: $mobile) {
      margin-left: 0px;
    }
    @media screen and (max-width: $tablet-port) {
      margin-left: 0px;
    }
  }

  .icon-holder {
    margin-top: -28px;
    @media screen and (max-width: $mobile) {
      margin-top: -22px;
    }
  }
  .title {
    padding-top: 30px;
    @media screen and (max-width: $mobile) {
      padding-top: 15px;
    }
  }
  .text {
    padding-left: 36px;
    padding-right: 36px;
    @media screen and (max-width: $mobile) {
      padding-left: 18px;
      padding-right: 18px;
    }
  }
}