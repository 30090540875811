@import '../../styles/variables';

.main .contact-form {
  width: 100%;
  height: 100%;
  padding-top: 114px;
  padding-bottom: 164px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #E9E6E2 ;
  text-align: center;
  @media (max-width: $mobile) {
    margin-top: 20px;
    padding-bottom: 65px;
  }

  .deco-line-1 {
    margin-top: -143px;
    @media (max-width: $mobile) {
      margin-top: -122px;
    }
  }

  .header-cf {
    margin-top: 74px;
    margin-bottom: 20px;
    @media (max-width: $mobile) {
      margin-top: 0px;
    }
  }

  .sub-header {
    font-size: $font-size-regular;
    font-family: "Kanit ExtraLight";
    width: 1070px;
    margin-bottom: 60px;
    @media (max-width: $mobile) {
      font-size: $font-size-regular-mobile;
      width: 85%;
    }
  }
}

.inc-form {
  width: 960px;

  @media (max-width: $mobile) {
    width: 375px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .inc-text-input {
    width: 472px;
    height: 64px;
    border: none;
    border-radius: 5px;
    background: #f2f0ee;
    @media (max-width: $mobile) {
      width: 311px;
      height: 40px;
    }

    &:focus {
      background: #ffffff;
    }

    &.textarea {
      width: 100%;
      height: 144px;

      @media (max-width: $mobile) {
        padding-top:15px;
        width: 311px;
        height: 80px;
      }
    }
  }

  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;

    &.button {
      @media (max-width: $mobile) {
       width: 311px;
        .btn-holder {
          align-self: start;
        }
        .material-icons {
          font-size: 10px;
        }
      }
    }

    @media (max-width: $mobile) {
      justify-content: space-between;
      height: 100px;
      flex-direction: column;
    }
  }

  .formErrorsList {
    h3 {
      margin-bottom: 20px;
    }

    .fieldErrors{
      margin-bottom: 20px;
      text-align: left;
      color: #FE2911;
    }
  }

  .invalid {
    display: none;
  }
}