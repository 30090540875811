@import '../../styles/variables';

.top-bar {
  width: 100%;
  height: 120px;
  position: fixed;
  z-index: 2000;
  font-family: "Kanit Light";

  .logo {
    width: 108px;
    height: 73px;
    margin-top: 35px;
    margin-left: 70.5px;
    background-image: url("../../assets/deco/icons/include-logo-topbar.svg");
    background-repeat: no-repeat;
    @include transition(all 0.2s linear);
    @media screen and (max-width: $tablet-land), (max-width: $tablet-port)  {
      background-image: url("../../assets/deco/icons/include-logo-topbar-tab.svg");
      width: 82px;
      height: 56px;
    }
  }

  .top-menu {
    margin-top: 59px;
    margin-right: 20px;
    @include transition(all 0.2s linear);
    @media screen and (max-width: $tablet-land), (max-width: $tablet-port)  {
      margin-top: 48px;
    }
  }

  &.scroll {
    background: $primary-header-gradient;
    height: 80px;
    .logo {
      width: 72px;
      height: 48.8px;
      margin-top: 14px;
      background-image: url("../../assets/deco/icons/include-logo-topbar-scrolled.svg");
      background-repeat: no-repeat;
    }
    .top-menu {
      margin-top: 25px;
    }
  }

  @media screen and (max-width: $mobile) {
    .logo {
      background-image: url("../../assets/deco/icons/include-logo-topbar-mobile.svg");
      background-repeat: no-repeat;
      width: 55px;
      height: 36px;
      margin-top: 21px;
      margin-left: 24px;
    }

    .top-menu {
      margin-top: 14px;
      margin-right: 0px;
    }

    &.scroll {
      background: $primary-header-gradient;
      height: 56px;
      .logo {
        margin-top: 10px;
        background-image: url("../../assets/deco/icons/include-logo-topbar-mobile.svg");
        background-repeat: no-repeat;
        width: 55px;
        height: 36px;
        margin-left: 24px;
      }

      .top-menu {
        margin-top: 0px;
        margin-right: 0px;
      }
    }
  }
}
