@import '../../styles/variables';

.service-card {
  display: flex;
  flex-direction: row;
  padding-bottom: 60px;

  @media screen and (max-width: $mobile) {
    padding-bottom: 35px;
  }

  &:nth-child(2n) {
    margin-left: 70px;
    @media screen and (max-width: $tablet-land) {
      margin-left: 75px;
    }
    @media screen and (max-width: $mobile) {
      margin-left: 0px;
    }
    @media screen and (max-width: $tablet-port) {
      margin-left: 0px;
    }
  }

  p {
    margin-bottom: 15px;
  }

  .card-deco {
    flex-direction: column;
    align-items: center;
    width: 52px;
    overflow: hidden;

    .deco-border-wrapper {
      height: 100%;
      display: flex;
    }
  }

  .card-content {
    height: 100%;
    font-family: "Kanit ExtraLight";
    font-size: $font-size-regular;
    color: $content-text-color;
    width: 100%;

    a {
      color: $content-text-color;
      font-weight: bold;
    }

    @media screen and (max-width: $tablet-land) {
      font-size:$font-size-extra-small;
      width: 100%;
    }

    @media screen and (max-width: $tablet-port) {
      font-size:$font-size-extra-small;
      width: 590px;
    }

    @media screen and (max-width: $mobile) {
      font-size: $font-size-regular-mobile;
      width: 325px;
    }

    .header-row {
      display: flex;
      flex-direction: row;
    }

    .card-heading {
      font-family: "Kanit Medium";
      text-transform: uppercase;
      padding-top: 8px;
      padding-bottom: 24px;
      padding-left: 17px;

      @media screen and (max-width: $tablet-land) {
        font-size: $font-size-extra-small;
      }

      @media screen and (max-width: $mobile) {
        font-size: $font-size-regular-mobile;
        padding-bottom: 16px;
      }
    }

    .card-text {
      border-left: 1px solid #D9D9D9;
      margin-left: 25px;
      padding-left: 40px;
      &.no-border {
        border: none;
      }

      @media screen and (max-width: $mobile) {
        margin-left: 16px;
        padding-left: 33px;
      }
    }
  }
}

.service-card-single {
  display: flex;
  flex-direction: row;
  padding-bottom: 95px;
  @media screen and (max-width: $mobile) {
    padding-bottom: 40px;
  }
  p {
    margin-bottom: 15px;
  }
  .card-deco {
    flex-direction: column;
    align-items: center;
    width: 52px;
    overflow: hidden;

    .deco-border-wrapper {
      height: 100%;
      display: flex;
    }
  }

  .card-content {
    height: 100%;
    font-family: "Kanit ExtraLight";
    font-size: $font-size-regular;
    color: $content-text-color;
    width: 100%;

    a {
      color: $content-text-color;
      font-weight: bold;
    }

    @media screen and (max-width: $tablet-land) {
      font-size:$font-size-extra-small;
      width: 100%;
    }

    @media screen and (max-width: $tablet-port) {
      font-size:$font-size-extra-small;
      width: 590px;
    }

    @media screen and (max-width: $mobile) {
      font-size: $font-size-regular-mobile;
      width: 100%;
    }

    .header-row {
      display: flex;
      flex-direction: row;
    }

    .card-heading {
      font-family: "Kanit SemiBold";
      font-size: 28px;
      text-transform: uppercase;
      padding-top: 4px;
      padding-bottom: 13px;
      padding-left: 35px;

      @media screen and (max-width: $tablet-land) {
        font-size: $font-size-extra-small;
      }

      @media screen and (max-width: $mobile) {
        font-size: $font-size-extra-small;
        padding-bottom: 16px;
        padding-left: 10px;
      }
    }

    .card-text {
      border-left: 1px solid #D9D9D9;
      margin-left: 25px;
      padding-left: 58px;
      &.no-border {
        border: none;
      }

      @media screen and (max-width: $mobile) {
        margin-left: 16px;
        padding-left: 25px;
      }
    }
    .learn-more-btn {
      padding-top: 10px;
      margin-left: 83px;
      > button {
        height: 56px;
      }
      @media screen and (max-width: $mobile) {
        margin-left: 41px;
        > button {
          height: initial;
        }
      }
    }
    .non-mobile-float-right {
      @media screen and (min-width: $mobile) {
        float:right;
      }
    }
  }
}
