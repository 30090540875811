@import '../../styles/variables';

.promo-card-right, .promo-card-left {
  width: 92%;
  height: 100%;
  font-size: $font-size-regular;
  display: flex;
  justify-content: center;
  align-items: center;

  .card-content {
    height: 100%;
    display: flex;
    justify-content: center;
    width: $content-desktop-width + 256;
    flex-flow: inherit;
    @media screen and (max-width: $tablet-land ) {
      width: 948px;
      font-size: $font-size-extra-small;
      flex-flow: inherit;
    }
    @media screen and (max-width: $tablet-port ) {
      width: 85%;
      flex-flow: column;
    }
    @media screen and (max-width: $mobile ) {
      font-size: $font-size-regular-mobile;
      width: 85%;
      flex-flow: column;
    }

    .card-text, .promo-text-wrapper {
      width: 500px;
      @media screen and (max-width: $desktop ) {
        width: 440px;
        font-size: $font-size-small;
      }
      @media screen and (max-width: $tablet-land ) {
        width: 350px;
        font-size: $font-size-extra-small;
      }
      @media screen and (max-width: $tablet-port ) {
        width: 100%;
        order: 2;
      }
      @media screen and (max-width: $mobile ) {
        font-size: $font-size-regular-mobile;
        width: 100%;
        order: 2;
      }
    }
  }
  
  .promo-heading {
    font-family: "Kanit Bold";
    font-size: 28px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  
  .promo-img-wrapper {
    border-radius: 15px;
    overflow: hidden;  
    width: 650px;   //Desktop
    height: 366px;
      @media screen and (max-width: 1300px ) and (min-width: 1100px ) {
        width: 385px;
        height: 217px;
      }
      @media screen and (max-width: 1099px ) and (min-width: 812px ) {
        width: 385px;
        height: 217px;
        order: 1;
      }
      @media screen and (max-width: 950px ) {
        font-size: $font-size-regular-mobile;
        width: 350px;
        height: 460px;
        order: 1;
      }

      @media screen and (max-width: 600px ) {
        font-size: $font-size-regular-mobile;
        width: 100%;
        order: 1;
      }

      .isDesktop { 
        width: 650px;
        height: 366px;
      }
      .isTabletLand {
          width: 385px;
          height: 217px;
      }

      .isTabletPort {
          width: 350px;
          height: 460px;
      }

      .isMobile {
          width: 100%;
          height: 100%;
      }
  }
}
.promo-card-left {
  .card-content {
    .promo-text-wrapper {
      order: 2;
      margin-left: 50px;
      @media screen and (max-width: $desktop ) {
        order: 2;
        margin-left: 50px;
      }
      @media screen and (max-width: $tablet-land ) {
        order: 2;
        margin-left: 40px;
      }
      @media screen and (max-width: $tablet-port ) {
        margin-left: inherit;
        order: 2;
      }
      @media screen and (max-width: $mobile ) {
        margin-left: inherit;
        order: 2;
      }
    }
    .promo-img-wrapper {
      order: 1;
        @media screen and (max-width: 1300px ) and (min-width: 1100px ) {
          order: 1;
        }
        @media screen and (max-width: 1099px ) and (min-width: 812px ) {
          order: 1;
        }
        @media screen and (max-width: 950px ) {
          order: 1;
        }
      }
    }
}

.promo-card-right.useFullWidth, .promo-card-left.useFullWidth {
  width: 100%;
}