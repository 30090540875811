@import '../../styles/variables';

.header {
    width: 100%;
    height: 815px;
    justify-content: center;
    color: $primary-white;
    background-image: url("../../assets/desktop/header-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 1000;
    position: relative;

    &.practice-header{
        background-image: url("../../assets/desktop/practice-header-bg.svg");
        background-size: cover;
        height: 0;
        padding: 0; /* reset */
        padding-bottom: 42%;
        .content {
            padding-top: 161px;
        }
        @media screen and (max-width: $tablet-land) {
            background-image: url("../../assets/tablet/practice-header-bg.svg");
            padding-bottom: 48%;
        }
        @media screen and (max-width: $tablet-port) {
            .content {
                padding-top: 100px;
            }
        }
        @media screen and (max-width: $mobile) {
            background-image: url("../../assets/mobile/practice-header-bg.svg");
            font-size: $font-size-medium;
            padding-bottom: 80%;
            .content {
                padding-top: 85px;
            }
        }
    }

    &.optimizely-header{
        background-image: url("../../assets/desktop/optimizely-header-bg.svg");
        background-size: cover;
        height: 0;
        padding: 0; /* reset */
        padding-bottom: 42%;
        .content {
            padding-top: 161px;
        }
        @media screen and (max-width: $tablet-land) {
            background-image: url("../../assets/tablet/opti-tablet-header-bg.svg");
            padding-bottom: 48%;
        }
        @media screen and (max-width: $tablet-port) {
            .content {
                padding-top: 100px;
            }
        }
        @media screen and (max-width: $mobile) {
            background-image: url("../../assets/mobile/opti-mobile-header-bg.svg");
            font-size: $font-size-medium;
            padding-bottom: 80%;
            .content {
                padding-top: 85px;
            }
        }
    }

    &.uniform-header{
        background-image: url("../../assets/desktop/uniform-header-bg.svg");
        background-size: cover;
        height: 0;
        padding: 0; /* reset */
        padding-bottom: 42%;
        .content {
            padding-top: 161px;
        }
        @media screen and (max-width: $tablet-land) {
            background-image: url("../../assets/tablet/uniform-tablet-header-bg.svg");
            padding-bottom: 48%;
        }
        @media screen and (max-width: $tablet-port) {
            .content {
                padding-top: 100px;
            }
        }
        @media screen and (max-width: $mobile) {
            background-image: url("../../assets/mobile/uniform-mobile-header-bg.svg");
            font-size: $font-size-medium;
            padding-bottom: 80%;
            .content {
                padding-top: 85px;
            }
        }
    }


    &.narrow-secondary {
        background-image: url("../../assets/desktop/header-secondary-bg.svg");
        background-size: cover;
        height: 0;
        padding: 0; /* reset */
        padding-bottom: 42%;
        .content {
            padding-top: 161px;
        }
        @media screen and (max-width: $tablet-land) {
            background-image: url("../../assets/tablet/header-secondary-bg.svg");
            padding-bottom: 48%;
            .content {
                padding-top: 122px;
            }
        }
        @media screen and (max-width: $mobile) {
            height: 320px;
            background-image: url("../../assets/mobile/header-narrow-bg.svg");
            font-size: $font-size-medium;
            .content {
                padding-top: 85px;
            }
        }
    }

    &.narrow {
        background-image: url("../../assets/desktop/header-narrow-bg.svg");
        height: 439px;
        .content {
            padding-top: 161px;
        }

        @media screen and (max-width: $tablet-land) {
            background-image: url("../../assets/tablet/header-narrow-bg.svg");
            height: 304px;
            .content {
                padding-top: 122px;
            }
        }

        @media screen and (max-width: $mobile) {
            height: 229px;
            background-image: url("../../assets/mobile/header-narrow-bg.svg");
            font-size: $font-size-medium;
            .content {
                padding-top: 85px;
            }
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 222px;

        .page-header {
            letter-spacing: 1.3px;
            text-align: center;
        }

        .desc {
            text-align: center;
            width: 905px;

            @media screen and (max-width: $tablet-land) {
                width: 715px;
            }
            @media screen and (max-width: $tablet-port) {
                width: 559px;
            }
            @media screen and (max-width: $mobile) {
                width: 85%;
            }

        }
        .action {
            margin-top: 66px;
        }

        @media screen and (max-width: $tablet-land) {
            padding-top: 190px;
            .action {
                margin-top: 40px;
            }
        }

        @media screen and (max-width: $tablet-port) {
            padding-top: 145px;
            .action {
                margin-top: 30px;
            }
        }

        @media screen and (max-width: $mobile) {
            padding-top: 89px;

            .action {
                margin-top: 40px;
            }
        }
    }

    @media screen and (max-width: $tablet-land) {
        font-size: $font-size-48;
        height: 660px;
        background-image: url("../../assets/tablet/header-bg-land.svg");
    }

    @media screen and (max-width: $tablet-port) {
        font-size: $font-size-48;
        background-image: url("../../assets/tablet/header-bg-port.svg");
    }

    @media screen and (max-width: $mobile) {
        height: 460px;
        background-image: url("../../assets/mobile/header-bg.svg");
        font-size: $font-size-medium;
    }
}


