@import '../../styles/variables';

.include-footer {
  width: 100%;
  height: 671px;
  background-image: url("../../assets/footer-descktop.svg");
  margin-top: -92px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: $mobile) {
    background-image: url("../../assets/footer-mobile.svg");
    background-repeat: no-repeat;
    height: 486px;
  }

  .copy-right {
    font-family: "Kanit Light";
    font-size: $font-size-extra-small;
    color: $content-subheader-color;
    padding-bottom: 30px;
    @media (max-width: $mobile) {
      font-size: $font-size-super-extra-small;
    }
  }

  .include-footer-wrapper {
    display: flex;
    justify-content: space-between;
    width: 1040px;
    height: 100%;
    padding-top: 160px;

    @media (max-width: $mobile) {
      width: 85%;
      flex-wrap: wrap;
      padding-top: 70px;
    }

    .inc-footer-column {
      display: flex;
      flex-direction: column;
      width: 277px;
      @media (max-width: $mobile) {
        width: 130px;

        &.site-map-mobile {
          width: 102px;
        }

        &.social-mobile {
          width: 340px;
        }

      }


      .inc-footer-column-header {
        font-family: "Kanit Bold";
        font-size: $font-size-small;
        text-transform: uppercase;
        color: $primary-white;
        padding-bottom: 17px;
        @media (max-width: $mobile) {
          font-size: $font-size-regular-mobile;
        }
      }

      .links {
        padding-top: 41px;
        @media (max-width: $mobile) {
          padding-top: 20px;
        }
      }

      .site-map-links {
        display: flex;
        flex-direction: column;
      }

      .social {
        .icon-label {
          margin-bottom: 15px;
        }

        a {
          padding-left: 10px;
        }
      }

      .contact-text {
        font-family: "Kanit Light";
        font-size: $font-size-regular;
        color: $content-subheader-color;
        line-height: 41px;

        @media (max-width: $mobile) {
          font-size: $font-size-regular-mobile;
          line-height: 20px;

          &.social-mobile {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        a {
          font-family: "Kanit Light";
          font-size: $font-size-regular;
          color: $content-subheader-color;
          text-decoration: none;
          @media (max-width: $mobile) {
            font-size: $font-size-regular-mobile;
          }
        }

        .icon-label {
          display: flex;
          flex-direction: row;
          .linkedin-ico {
            background-image: url("../../assets/deco/icons/linkdin-ico.png");
            width: 37px;
            height: 41px;

            @media all and (max-width: $tablet-land), (max-width: $tablet-port) {
              background-image: url("../../assets/deco/icons/linkdin-ico-tab.png");
              width: 31px;
              height: 34px;
            }
            @media (max-width: $mobile) {
              background-image: url("../../assets/deco/icons/linkdin-ico-mob.png");
              width: 25px;
              height: 27px;
            }
          }
          .facebook-ico {
            background-image: url("../../assets/facebook-ico.svg");
            background-repeat: no-repeat;
            width: 37px;
            height: 40px;
            @media (max-width: $mobile) {
              width: 24px;
              height: 26px;
            }
          }
          .twitter-ico {
            background-image: url("../../assets/tweeter-ico.svg");
            background-repeat: no-repeat;
            width: 37px;
            height: 40px;
            @media (max-width: $mobile) {
              width: 24px;
              height: 26px;
            }
          }

          .pl-10 {
            padding-left: 10px;
          }
        }
      }
    }
  }


}