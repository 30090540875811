@import '../../styles/variables';

.brand-reference-card {
  display: flex;
  flex-direction: row;
  padding-bottom: 60px;
  padding-right: 80px;

  @media screen and (max-width: $mobile) {
    padding-bottom: 35px;
  }

  p {
    margin-bottom: 15px;
  }

  .card-deco {
    flex-direction: column;
    align-items: center;
    width: 52px;
    overflow: hidden;

    .deco-border-wrapper {
      height: 100%;
      display: flex;
    }
  }

  .brand-reference-card-content {
    height: 100%;
    font-family: "Kanit ExtraLight";
    font-size: $font-size-regular;
    color: $content-text-color;
    width: 100%;

    a {
      color: $content-text-color;
      font-weight: bold;
    }

    @media screen and (max-width: $tablet-land) {
      font-size:$font-size-extra-small;
      width: 100%;
    }

    @media screen and (max-width: $tablet-port) {
      font-size:$font-size-extra-small;
      width: 188px;
    }

    @media screen and (max-width: $mobile) {
      font-size: $font-size-regular-mobile;
      width: 188px;
    }

    .header-row {
      display: flex;
      flex-direction: row;
    }

    .card-heading {
      font-family: "Kanit Medium";
      text-transform: uppercase;
      padding-top: 8px;
      padding-bottom: 24px;
      padding-left: 17px;

      @media screen and (max-width: $tablet-land) {
        font-size: $font-size-extra-small;
      }

      @media screen and (max-width: $mobile) {
        font-size: $font-size-regular-mobile;
        padding-bottom: 16px;
      }
    }

    .card-text {
      border-left: 1px solid #D9D9D9;
      margin-left: 25px;
      padding-left: 40px;
      &.no-border {
        border: none;
      }

      @media screen and (max-width: $mobile) {
        margin-left: 16px;
        padding-left: 33px;
      }
    }
  }
}
