@font-face {
  font-family: "Kanit Light";
  src: url("../assets/fonts/Kanit/Kanit-Light.ttf");
}

@font-face {
  font-family: "Kanit ExtraLight";
  src: url("../assets/fonts/Kanit/Kanit-ExtraLight.ttf");
}

@font-face {
  font-family: "Kanit Regular";
  src: url("../assets/fonts/Kanit/Kanit-Regular.ttf");
}

@font-face {
  font-family: "Kanit Bold";
  src: url("../assets/fonts/Kanit/Kanit-Bold.ttf");
}

@font-face {
  font-family: "Kanit Medium";
  src: url("../assets/fonts/Kanit/Kanit-Medium.ttf");
}