@import '../../styles/variables';

.header-navigation {
  display: flex;
  font-size: $font-size-extra-small;
  color: $primary-white;
  letter-spacing: 2px;

  .header-bar {
    margin-top: 4px;
    margin-right: 5px;
  }

  .menu-group {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;

    &:hover > .menu-list-wrapper, &:active > .menu-list-wrapper {
      display: block;
      ~.label {
        background-color:  #E37363;
        opacity: 1;
      }
      @media screen and (max-width: $tablet-land) {
        visibility: visible;
      }
    }

    .label {
      text-align: center;
      cursor: pointer;
      z-index: 1000;
      padding: 5px 20px;
      border-radius: 20px;
      opacity: 0.6;
      &:hover, &:active {
        background-color: #E37363;
        opacity: 1;
      }
      &.is-active {
        opacity: 1;
      }
    }

    .menu-list-wrapper {
      padding-top: 45px;
      position: absolute;
      display: none;

      @media screen and (max-width: $tablet-land) {
        display: block;
        visibility: hidden;
      }

      .menu-list {
        background-color: $primary-white;
        border-radius: 8px;
        -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

        .menu-list-items {
          padding-top: 24px;
          padding-bottom: 38px;

          &.active-list {
            background: #EBEBEB;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          .menu-list-item {
            display: flex;
            flex-direction: column;
            padding-left: 24px;
            padding-right: 24px;
            line-height: 37px;
            text-transform: capitalize;

            &.active {
              background-color: $primary-white;
              .parent-link {
                .menu-list-ico {
                  display: block;
                }
              }
            }

            .parent-link {
              font-family: "Kanit Medium";
              font-size: $font-size-extra-small;
              text-transform: uppercase;
              color: $nested-menu-parent-item-color;
              display: flex;
              justify-content: space-between;
              cursor: pointer;

              .menu-list-ico {
                background-image: url("../../assets/deco/icons/top-menu-arrow.png");
                width: 18px;
                height: 11px;
                align-self: center;
                display: none;
              }
            }

            .link-item {
              font-family: "Kanit Light";
              font-size: $font-size-extra-small;
              color: $nested-menu-link-item-color;
              letter-spacing: initial;
              padding-left: 20px;
              padding-right: 20px;
              white-space: nowrap;

              &.active {
                font-family: 'Kanit Medium';
                color: #333333;
              }
              &:hover, &:active {
                background-color: #D74C49;
                color: $primary-white;
                border-radius: 20px;
              }
            }
          }
        }

        .menu-list-sub {
          width: 276px;
          &.hide {
            display: none;
          }

          .sub-list-header {
            font-family: "Kanit Medium";
            font-size: $font-size-extra-small;
            text-transform: uppercase;
            color: #333333;
            letter-spacing: initial;
            padding-top: 34px;
            padding-left: 45px;
          }

          .menu-list-items {
            padding-top: 15px;
            padding-right: 30px;
            padding-left: 30px;
            .menu-list-item {
              line-height: 30px;
              padding-left: 0px;
            }
          }
        }
      }
    }
  }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; right: -100px }
  100% { opacity: 1; right: 0px }
}

@keyframes fadeIn {
  0% { opacity: 0; right: -100px }
  100% { opacity: 1; right: 0px }
}

@-webkit-keyframes fadeOut {
  0% { opacity: 1; right: 0px}
  100% { opacity: 0; right: -327px}
}

@keyframes fadeOut {
  0% { opacity: 1; right: 0px}
  100% { opacity: 0; right: -327px}
}

.mobile-navigation {
  font-family: "Kanit Light";
  letter-spacing: 2px;

  .hide {
    visibility: hidden;
  }

  .mobile-menu {
    flex-direction: column;
    width: 327px;
    background: $primary-white;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 3000;

    &.opened {
      right: 0px;
      -webkit-animation-name: fadeIn;
      -webkit-animation-duration: 0.3s;
      animation-name: fadeIn;
      animation-duration: 0.3s;
    }

    &.closed {
      right: -327px;
      -webkit-animation-name: fadeOut;
      -webkit-animation-duration: 0.3s;
      animation-name: fadeOut;
      animation-duration: 0.3s;
    }

    .nav-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .close {
        padding-right: 6px;
      }
      .selected-header {
        font-size: 16px;
        font-family: "Kanit SemiBold";
        color: #B2B2B2;
        text-transform: uppercase;
      }
      .return {
        padding-left: 4px;
      }
    }

    .menu-items {
      width: 100%;
      height: 100%;
      margin-top: 5px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      .menu-item {
        text-transform: uppercase;
        .sub-items {
          font-size: 16px;
          font-family: "Kanit Light";
          text-transform: capitalize;
        }
        .sub-items-overview {
          text-transform: capitalize;
        }
        .master-link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .material-icons {
            margin-right: 10px;
          }
        }
        &.active {
          border-left: 4px solid #FF6F21;
          a, .master-link {
            color: #333333;
          }
          padding-left: 31px;
        }
        padding-left: 35px;
        a, .master-link {
          color: #999999;
          font-size: $font-size-regular;
          line-height: 2.5;
        }
      }
    }

    .sub-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding-top: 316px;
      padding-bottom: 35px;
      a {
        color: #999999;
        font-size: 14px;
        line-height: 3;
        text-transform: uppercase;
        padding-left: 35px;
      }
    }
  }
}

