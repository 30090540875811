@import '../../styles/variables';

// Landing page
.sub-header-block {
  width: 100%;
  height: 100%;
  background-color: #E9E6E2 ;
  font-family: "Kanit ExtraLight";
  text-align: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: -200px;
  z-index: -1;
  color: $primary-gray;
  .sub-header-wrapper {
    margin-top: 282px;
    margin-bottom: 152px;
    .main {
      font-size: $font-size-medium;

      @media screen and (max-width: $tablet-land) {
        font-size: $font-size-large-mobile;
      }

      @media screen and (max-width: $mobile) {
        font-size: $font-size-small;
      }

    }
    .sub-block {
      margin-top: 63px;
      font-family: "Kanit Regular";
      font-size: $font-size-extra-small;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $secondary-gray;

      @media screen and (max-width: $mobile) {
        font-size: $font-size-super-extra-small;
      }
    }
    @media screen and (max-width: $mobile) {
      margin-top: 215px;
      margin-bottom: 70px;
    }
  }
}

.our-team-block {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  color: $content-text-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $tablet-port) {
    flex-direction: column-reverse;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column-reverse;
  }

  .team-bg {
    background-image: url("../../assets/deco/our-team-photo.svg");
    background-repeat: no-repeat;
    width: 520px !important;
    height: 588px;
    margin-left: 22px;

    @media screen and (max-width: $tablet-land) {
      background-image: url("../../assets/deco/tablet/our-team-photo.svg");
      width: 406px !important;
      height: 460px;
    }

    @media screen and (max-width: $mobile) {
      background-image: url("../../assets/deco/mobile/our-team-photo.svg");
      width: 331px;
      height: 340px;
      margin-bottom: 75px;
    }
  }

  .action {
    margin-top: 46px;
    @media screen and (max-width: $mobile) {
      margin-top: 30px;
    }
  }

  .col {
    width: 591px;
    @media screen and (max-width: $tablet-land) {
      width: 444px;
    }
    @media screen and (max-width: $tablet-port) {
      width: 600px;
    }
    @media (max-width: $mobile) {
      width: 295px;
    }
  }
}

// About page
.attention-block {
  font-size: $font-size-medium;
  color: $content-arrow-icon-color;
  text-align: center;
  width: 840px;
  align-self: center;

  @media (max-width: $tablet-port) {
    font-size: $font-size-large-mobile;
    width: 600px;
  }

  @media (max-width: $mobile) {
    font-size: $font-size-small;
    margin-bottom: 20px;
    width: 295px;
  }
}

.map, .maps-wrapper {
  width: 100%;
  height: 600px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  @media (max-width: $mobile) {
    font-size: $font-size-super-extra-small;
    margin-top: 10px;
  }

  .map-heading {
    font-family: "Kanit ExtraLight";
    font-size: $font-size-regular;
    color: $content-text-color;
    font-style: italic;
    padding-bottom: 15px;

    @media (max-width: $mobile) {
      font-size: $font-size-regular-mobile;
      width: 85%;
    }
  }
}

.maps-wrapper {
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: $mobile) {
      flex-direction: inherit;
    }

  .doubleMap {
    width: 48%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;

    @media (max-width: $mobile) {
        width: 100%;
        margin-bottom: 20px;
      }
  }
}
// Contact Us Page
.contact-page
{
  .contact-form
  {
    margin-top: -100px;
    padding-top: 154px;
  }
}

// Privacy
.privacy-link {
  color: blue !important;
}